import React, { useState } from 'react'
import { Checkbox, Message } from 'semantic-ui-react'
import { useRouter } from 'next/router'

import environment from 'environment'

import { PATREON_TIERS, ROLES } from 'types/user'

import UserService from 'services/user.service'

import PhatInput from 'components/formElements/PhatInput'
import PhatButton from 'components/formElements/PhatButton'
import SimpleSpinner from 'components/elements/SimpleSpinner'
import SimplePageWrapper from 'components/misc/SimplePageWrapper'
import Link from 'components/elements/Link'

import styles from './login.module.scss'

const LoginPage = () => {
  const router = useRouter()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const [usernameOrEmail, setUserNameOrEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(true)

  const handleLoginSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    setLoading(true)
    setError('')

    UserService.login(usernameOrEmail, password, rememberMe)
      .then(res => {
        const hasAccess =
          res.user.profile.roles.includes(ROLES.TESTER) ||
          Number(res.user.patreonAccount?.pledge?.level) >= PATREON_TIERS.UNCOMMON

        if (environment.runningOnPrivateServer && !hasAccess) return router.push('/connect-patreon')

        if (router.query.redirectTo?.toLocaleString().toLowerCase().includes('javascript')) {
          // @ts-ignore - prevent XSS in a jank way
          window.location = '/'

          return
        }

        // Odd case due to the way we handle URL encoding
        if (router.query.redirectTo?.toLocaleString().includes('/cardImport')) {
          // @ts-ignore
          window.location = `/cardImport?c=${encodeURIComponent(router.query.redirectTo.replace('/cardImport?c=', ''))}`

          return
        }

        if (router.query.redirectTo) {
          const sanitizedRedirect = `${router.query.redirectTo}`.replace('//', '/')

          // @ts-ignore
          // Next doesn't update cookies properlly when using router.push, so we redirect them manually.
          // NextJS is trash - https://github.com/vercel/next.js/issues/14927
          window.location = decodeURIComponent(sanitizedRedirect)
        }
        // @ts-ignore
        else window.location = '/'
      })
      .catch(e => {
        console.error(e)

        setLoading(false) // Only set loading to false on failure so the loader stays up until the page reloads

        if (!e.non_field_errors) return setError('Unable to login with the provided credentials.')

        // prettier-ignore
        if (e.non_field_errors[0] === 'E-mail is not verified.') setError(e.non_field_errors[0] + ' For your convenience, a new confirmation email has been sent.')
        else setError(e.non_field_errors[0])
      })
  }

  return (
    <SimplePageWrapper className={styles.wrapper} title="Archidekt login">
      <div className={styles.branding}>
        <img className={styles.logo} alt="Archidekt basic logo" src="/images/archidekt_basic.svg" height="100" />
        Archidekt {environment.runningOnPrivateServer && 'development'} login
      </div>
      {environment.runningOnPrivateServer && (
        <div className={styles.developmentWarning}>
          <Link href="https://archidekt.com">
            <button>
              <span>This is the Archidekt beta website</span>
              <span>Click here to go to Archidekt.com</span>
            </button>
          </Link>
        </div>
      )}

      <form className={styles.form} onSubmit={handleLoginSubmit} method="post" name="archidekt-login-form">
        <PhatInput
          name="username"
          header="Username"
          placeholder="Username or Email"
          onChange={setUserNameOrEmail}
          focusOnMount
          inlineHeader
          autocomplete="username"
          type="text"
          uncontrolledInput
        />
        <PhatInput
          header="Password"
          name="password"
          type="password"
          placeholder="Password"
          onChange={setPassword}
          inlineHeader
          autocomplete="current-password"
          uncontrolledInput
        />

        <PhatButton disabled={loading} type="submit" color="green">
          {loading ? (
            <span className={styles.loading}>
              <SimpleSpinner size="xSmall" />
              Checking info!
            </span>
          ) : (
            'Login'
          )}
        </PhatButton>
      </form>
      {error && <Message error header="Login failed" content={error} />}

      <Checkbox
        label="Remember Me"
        className={styles.rememberMe}
        onChange={() => setRememberMe(!rememberMe)}
        checked={rememberMe}
        name="rememberMe"
        type="checkbox"
      />

      <div className={styles.extras}>
        <span>Forgot your password?</span>
        <Link href="/forgot-password">Reset password</Link>
      </div>
    </SimplePageWrapper>
  )
}

export default LoginPage
